import React, { useEffect, useState } from "react";
import { Typography, Paper, Button, TextField } from "@mui/material";
import { useCreateAsset } from "../../api/createApiFunctions";
import { toast } from "react-toastify";
import SimpleBackdrop from "../SimpleBackdrop/SimpleBackdrop";

const CreateAssetForm = ({ handleShowAssetQuestion }) => {
  // Second form state
  const [assetName, setAssetName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  //To create asset for report user
  const {
    mutate: createAsset,
    isSuccess: createAssetIsSuucess,
    error: createAssetError,
    isError: createAssetIsError,
    isLoading: createAssetIsLoading,
    data: assetData,
  } = useCreateAsset();

  useEffect(() => {
    if (createAssetIsSuucess) {
      //We need to pass cardID for opening the questions card
      handleShowAssetQuestion(assetData?.data?.card_id);
    }
  }, [assetData]);

  useEffect(() => {
    if (createAssetIsError) {
      toast.error(createAssetError?.response?.data?.error[0]);
    }
  }, [createAssetError]);

  const handleAssetInfoSubmit = (e) => {
    e.preventDefault();

    // Basic validation for the second form
    if (!assetName) {
      setError("Please fill in all fields");
      return;
    }

    const payload = {
      name: assetName,
      description: description,
    };
    createAsset(payload);

    // Reset the form and error state after submission
    setAssetName("");
    setDescription("");
    setError("");
  };

  const handleAssetNameChange = (e) => {
    setAssetName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        marginTop: "20px",
        width: "300px",
      }}
    >
      {createAssetIsLoading ? (
        <SimpleBackdrop
          openStat={createAssetIsLoading}
          text="Processing your request... This may take a few minutes"
        />
      ) : (
        <>
          <Typography component="h1" variant="h5" align="center">
            {"Add Asset"}
          </Typography>
          <form onSubmit={handleAssetInfoSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="assetName"
              label="Asset Name"
              name="assetName"
              value={assetName}
              onChange={handleAssetNameChange}
              error={error && !assetName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="description"
              label="Description"
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              error={error && !description}
            />
            {error && !assetName && (
              <Typography
                variant="body2"
                color="error"
                style={{ marginTop: "10px" }}
              >
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "1rem",
                background: "#ffffff",
                color: "#049547",
                border: " 2px solid #049547",
                borderRadius: "25px",
                boxShadow: "",
              }}
            >
              Add and Continue
            </Button>
          </form>
        </>
      )}
    </Paper>
  );
};

export default CreateAssetForm;
