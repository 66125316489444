import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import SectionTabs from "../modelCard/Tabs";
import CreateAssetQuestionsTable from "./CreateAssetQuestionsTable";
import {
  useGetAppQue,
  useGetScoresForReportUser,
} from "../../api/readApiFunctions";
import { getAnsCombination } from "../../services/cardServices";
import { useUpdateQueAnswers } from "../../api/updateApiFunctions";
import { dateTimeFormat } from "../../utils/basicUtils";
import { logoutUser } from "../../utils/authUtils";
import { analyticServices } from "../../services/analyticServices";
import SimpleBackdrop from "../SimpleBackdrop/SimpleBackdrop";

const CreateAssetQuestionsForm = ({ showAssetQueAnsForm, handleShowPDF }) => {
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "80%",
    // bgcolor: "#E7EDF0",
    bgcolor: "#ffffff",

    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };

  //state for selected risk factor(tab) in cards
  const [selectedSection, setSelectedSection] = useState({
    title: "Fairness",
  });

  // the state used for set the card Response data  question data
  const [data, setData] = useState(null);

  //State editedQuestionData, to store edited values for question data(boolean, text & file)
  const [editedQuestionData, setEditedQuestionData] = useState([]);

  const [saveBtnState, setSaveBtnState] = useState(false);
  const [submitBtnState, setSubmitBtnState] = useState(false);
  const [showYesToallPopup, setShowYesToallPopup] = useState(false);

  const {
    data: cardResData,
    isLoading: cardResDataIsLoading,
    error,
    refetch: cardResDataRefetch,
  } = useGetAppQue();

  //To update questions data
  const {
    mutate: updateQueAnswers,
    isSuccess,
    data: cardQuestionDataRes,
  } = useUpdateQueAnswers();

  //To get the calculated scores
  const {
    mutate: scoresForReportUser,
    isSuccess: isSuccessScoresForReportUser,
    data: scoresForReportUserRes,
    error: errorScoresForReportUser,
    isError: isErrorScoresForReportUser,
    isLoading: isLoadingScoresForReportUser,
  } = useGetScoresForReportUser();

  const handleSelectedSection = (section) => {
    setSelectedSection(section);
  };

  useEffect(() => {
    if (cardResData) {
      setData(cardResData?.data?.responseData);
    }

    return () => {
      setData(null);
    };
  }, [cardResData]);

  useEffect(() => {
    if (data) {
      handleCheckAllQueAnswered();
    }
  }, [data]);

  useEffect(() => {
    if (isSuccessScoresForReportUser) {
      let scoreObjForEU = null;
      let appData = scoresForReportUserRes?.data
      scoresForReportUserRes?.data?.responseData?.forEach((scoreData) => {
        if (scoreData.ScoreName === "EU AI Act") {
          scoreObjForEU = scoreData;
        }
      });
      handleShowPDF(scoreObjForEU , appData);
    }
  }, [scoresForReportUserRes]);

  useEffect(() => {
    if (isErrorScoresForReportUser) {
      // toast.error(errorGetAppQueApi.response?.data?.error[0]);
    }
  }, [errorScoresForReportUser]);

  const handleCheckAllQueAnswered = () => {
    let allQuestionsAreAnswered = false;
    data?.sections.forEach((sectionData) => {
      sectionData.title !== "Details" &&
        sectionData.subsections?.forEach((subsectionData) => {
          subsectionData.title === "Questionnaire" &&
            subsectionData.questions?.forEach((questionData) => {
              if (questionData.is_visible === true) {
                if (
                  questionData.answer_type?.includes("boolean") &&
                  questionData?.answer_field !== null
                ) {
                  let stringAns = questionData?.answer_field.replace(/'/g, '"');
                  let parseAns = JSON.parse(stringAns);
                  if (
                    parseAns.hasOwnProperty("boolean") &&
                    parseAns.boolean !== ""
                  ) {
                    allQuestionsAreAnswered = true;
                  } else if (parseAns.boolean === "") {
                    allQuestionsAreAnswered = false;
                  }
                } else if (questionData?.answer_field === null) {
                  allQuestionsAreAnswered = false;
                }
              }
            });
        });
    });

    if (allQuestionsAreAnswered) {
      setSubmitBtnState(true);
    }
  };

  const handleLogout = () => {
    logoutUser();
    window.location.href = "https://konfer.ai/";
    analyticServices(`sign_out`);
  };

  /** Below function is used for storing the question data of all sections questions in one state */
  const handlEditedQuestionData = (queId, answerTypeKey, value) => {
    let alreadyPresent = false;
    editedQuestionData.length > 0 &&
      editedQuestionData.map((editedData) => {
        if (editedData.card_question_id === queId) {
          if (answerTypeKey === "file") {
            if (
              editedData.ans.file === undefined ||
              editedData.ans.file.length === 0
              // || editedData.ans.file === []
            ) {
              editedData.ans.file = [
                {
                  id: value.id,
                  file_name: value.file_name,
                },
              ];
            } else {
              editedData.ans.file.push({
                id: value.id,
                file_name: value.file_name,
              });
            }
          } else {
            editedData.ans[answerTypeKey] = value;
          }
          alreadyPresent = true;
        }
      });

    if (!alreadyPresent) {
      data.sections.map((secData) => {
        secData.title !== "Details" &&
          secData.subsections?.length > 0 &&
          secData.subsections?.map((subSecData) => {
            subSecData.title === "Questionnaire" &&
              subSecData.questions?.length > 0 &&
              subSecData.questions?.map((quesData) => {
                if (quesData.card_question_id === queId) {
                  let ansCombination = {};
                  ansCombination = getAnsCombination(
                    quesData.answer_type,
                    eval("(" + quesData.answer_field + ")")
                  );

                  if (answerTypeKey === "file") {
                    if (
                      ansCombination.file === undefined ||
                      ansCombination.file.length === 0
                      //|| ansCombination.file === []
                    ) {
                      ansCombination.file = [
                        {
                          id: value.id,
                          file_name: value.file_name,
                        },
                      ];
                    } else {
                      ansCombination.file.push({
                        id: value.id,
                        file_name: value.file_name,
                      });
                    }
                  } else {
                    ansCombination[answerTypeKey] = value;
                  }

                  setEditedQuestionData([
                    ...editedQuestionData,
                    {
                      card_question_id: queId,
                      ans: ansCombination,
                    },
                  ]);
                }
              });
          });
      });
    }

    setSaveBtnState(true);
    setSubmitBtnState(false);
  };

  const handleCardSave = () => {
    updateQueAnswers({
      type: data?.card_type,
      id: data?.reference_id,
      answer: editedQuestionData,
      storeAnswerFlag: editedQuestionData.length > 0 ? true : false,
    });
    if (editedQuestionData.length > 0) {
      setEditedQuestionData([]);
      setData(null);
    }
  };

  const handleCardSubmit = () => {
    scoresForReportUser();
  };

  /* Below function used to set yes answer to all questions who's answer type is boolean */
  const handleCardYesToAll = () => {
    let tempArrForEdited = [];
    data &&
      data.sections?.map((secData) => {
        secData.title !== "Details" &&
          secData.subsections?.length > 0 &&
          secData.subsections?.map((subSecData) => {
            subSecData.title === "Questionnaire" &&
              subSecData.questions?.length > 0 &&
              subSecData.questions?.map((quesData) => {
                const filteredData = editedQuestionData.filter(
                  (editedData) =>
                    editedData.card_question_id === quesData.card_question_id
                );

                if (filteredData.length > 0) {
                  if (filteredData[0].ans.hasOwnProperty("boolean")) {
                    if (quesData.desired_response === true) {
                      filteredData[0].ans.boolean = "yes";
                    } else {
                      filteredData[0].ans.boolean = "no";
                    }
                  }
                  tempArrForEdited.push(filteredData[0]);
                } else {
                  if (quesData.answer_type?.includes("boolean")) {
                    let ansCombination = {};
                    ansCombination = getAnsCombination(
                      quesData.answer_type,
                      eval("(" + quesData.answer_field + ")")
                    );

                    if (quesData.desired_response === true) {
                      ansCombination.boolean = "yes";
                    } else {
                      ansCombination.boolean = "no";
                    }
                    tempArrForEdited.push({
                      card_question_id: quesData.card_question_id,
                      ans: ansCombination,
                    });
                  }
                }
              });
          });
      });

    tempArrForEdited.length > 0 && setEditedQuestionData(tempArrForEdited);
    setSaveBtnState(true);
  };

  return (
    <>
      <Modal
        id="modalCard"
        sx={{
          marginLeft: 23,
          marginRight: 23,
        }}
        open={showAssetQueAnsForm}
        onClose={(e, reason) => {
          if (reason && reason === "backdropClick") return;

          //   setShowAssetQueAnsForm(false);
        }}
        aria-labelledby="Create-Asset-Questions-Form"
        aria-describedby="Create-Asset-Questions-Form-Description"
      >
        <Box sx={style} id="modalCardBox">
          <div id="modalCardCloseButton" className="close_button">
            <Tooltip title="Close and Logout">
              <IconButton
                onClick={handleLogout}
                sx={{ padding: 0, color: "#000000" }}
              >
                <Close sx={{ "&.MuiIconButton-root": { width: "22px" } }} />
              </IconButton>
            </Tooltip>
          </div>
          {cardResDataIsLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                component="h1"
                variant="h5"
                align="left"
                onContextMenu={() => {
                  setShowYesToallPopup(!showYesToallPopup);
                }}
                style={{ marginTop: "-10px" }}
              >
                {data?.display_name !== "" && data?.display_name !== null
                  ? data?.display_name
                  : data?.name !== "" || data?.name !== null
                  ? data?.name
                  : data?.title}
              </Typography>
              {data ? (
                data.sections.length > 0 && (
                  <>
                    <div
                      className="sections_container"
                      id="modalSectionsContainer"
                    >
                      <div className="sections" id="modalSectionsDiv">
                        <SectionTabs
                          sectionData={data.sections}
                          setSelectedSection={setSelectedSection}
                          selectedSection={selectedSection}
                          handleSelectedSection={handleSelectedSection}
                        />
                      </div>
                    </div>

                    {selectedSection.title?.toLocaleLowerCase() ===
                      "details" && (
                      <>
                        {/**Name */}
                        <p className="sub_title">Name</p>
                        <p className="sub_desc">
                          {selectedSection.entity?.name}
                        </p>

                        {/**Display Name */}
                        <p className="sub_title">Display Name</p>
                        <p className="sub_desc">
                          {selectedSection.entity?.display_name === null ||
                          selectedSection.entity?.display_name === ""
                            ? "-"
                            : selectedSection.entity?.display_name}
                        </p>
                        {/**Description */}
                        <p className="sub_title">Description</p>
                        <p className="sub_desc">
                          {selectedSection.entity?.description === null ||
                          selectedSection.entity?.description === "null" ||
                          selectedSection.entity?.description === ""
                            ? "-"
                            : selectedSection.entity?.description}
                        </p>

                        {/**Created at */}
                        <p className="sub_title">Created at</p>
                        <p className="sub_desc">
                          {selectedSection.entity?.created_at === null ||
                          selectedSection.entity?.created_at === ""
                            ? "-"
                            : dateTimeFormat(
                                selectedSection.entity?.created_at
                              )}
                        </p>
                      </>
                    )}

                    {selectedSection.title?.toLocaleLowerCase() !== "details" &&
                      selectedSection.subsections?.length > 0 &&
                      selectedSection.subsections?.map((subsectionsData) => {
                        return (
                          subsectionsData.title === "Questionnaire" &&
                          subsectionsData.questions.length > 0 && (
                            <CreateAssetQuestionsTable
                              sectionQuestionsData={subsectionsData}
                              handlEditedQuestionData={handlEditedQuestionData}
                              editedQuestionData={editedQuestionData}
                            />
                          )
                        );
                      })}
                  </>
                )
              ) : (
                <CircularProgress
                  style={{ marginLeft: "35vw", marginTop: "200px" }}
                />
              )}
              {data && data.sections.length > 0 && (
                <div
                  className="dataset_btn"
                  style={{ position: "absolute", bottom: 20, right: 20 }}
                >
                  {showYesToallPopup && (
                    <button
                      className="dataset_btn_active"
                      style={{ marginBottom: "0px" }}
                      onClick={handleCardYesToAll}
                    >
                      Respond to all
                    </button>
                  )}
                  <Tooltip placement="top" title={"Save responses"}>
                    <button
                      className={
                        saveBtnState
                          ? "dataset_btn_active"
                          : "dataset_btn_inactive"
                      }
                      style={{ marginBottom: "0px" }}
                      onClick={() => {
                        if (saveBtnState) {
                          handleCardSave();
                        }
                      }}
                    >
                      Save
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={
                      !submitBtnState
                        ? "Save responses before submitting"
                        : "Submit to generate report"
                    }
                  >
                    <button
                      className={
                        submitBtnState
                          ? "dataset_btn_active"
                          : "dataset_btn_inactive"
                      }
                      style={{ marginBottom: "0px" }}
                      onClick={() => {
                        submitBtnState && handleCardSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </Tooltip>
                </div>
              )}
            </>
          )}
          {isLoadingScoresForReportUser && (
            <SimpleBackdrop
              openStat={true}
              text={"Your report is being created..."}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default CreateAssetQuestionsForm;
