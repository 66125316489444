import React, { useState } from "react";
import { Typography, Paper, Button, TextField } from "@mui/material";
import axios from "axios";

const CreateAssetLogin = ({ handleLoggedIn }) => {
  const [emailID, setEmailID] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmailID(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!emailID || !password) {
      setError("Please fill in all fields");
      return;
    }

    // Your authentication logic here

    // try {
    //     const res = await axios.post("/auth/login", {
    //       emailID,
    //       password,
    //     });

    //     console.log("res.data.data.user", res.data.data.user)

    //   } catch (error) {
    //     const errMsg = error?.response?.data?.error
    //       ? error.response.data.error
    //       : errorToast.requestTryAgain;

    //     // validation response are in array , else all other respose are string
    //     if (Array.isArray(errMsg)) {
    //       errMsg.map((e) => {
    //         toast.error(e);
    //       });
    //     } else {
    //       toast.error(errMsg);
    //     }
    //   }

    // For demonstration purposes, we'll just log the data
    console.log("Email:", emailID);
    console.log("Password:", password);

    // Reset the form and error state after submission
    setEmailID("");
    setPassword("");
    setError("");
    handleLoggedIn();
  };

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", marginTop: "20px", width: "300px" }}
    >
      <Typography component="h1" variant="h5" align="center">
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="emailID"
          label="EmailID"
          name="email"
          autoComplete="email"
          value={emailID}
          onChange={handleEmailChange}
          error={error && !emailID}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={handlePasswordChange}
          error={error && !password}
        />
        {error && (!emailID || !password) && (
          <Typography
            variant="body2"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {error}
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
        >
          Login
        </Button>
      </form>
      {/* Your first form components */}
      {/* <Button onClick={handleLogin} variant="contained" color="primary">
      Login
    </Button> */}
    </Paper>
  );
};

export default CreateAssetLogin;
