import {
  FormControlLabel,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import PolicyIcon from "../../assets/images/adminPanel/Policy.svg";
import { PolicyNameConst } from "../../constants/policyConstants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "var(--fontFamilyText)",
    fontStyle: "normal",
    // fontWeight: "600",
    fontSize: "1rem",
    // color: "#6D8291",
    padding: "6px 0px 6px 10px",
    background: "#DDE6EA",
    // borderBottom: "1px solid #6D8291",

    // [theme.breakpoints.up("sm")]: {
    //   fontSize: 13.33,
    // },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: 13.33,
    // },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: 16,
    // },
    // [theme.breakpoints.up("xl")]: {
    //   fontSize: 18,
    // },
  },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "var(--fontFamilyText)",
    fontStyle: "normal",
    fontWeight: "400",
    //  fontSize: "18px",
    color: "#666666",
    padding: "4px 0px 4px 10px",
    borderBottom: "1px solid #d0dce2",

    // [theme.breakpoints.up("sm")]: {
    //   fontSize: 13,
    // },
    // [theme.breakpoints.up("md")]: {
    //   fontSize: 13,
    // },
    // [theme.breakpoints.up("lg")]: {
    //   fontSize: 16,
    // },
    // [theme.breakpoints.up("xl")]: {
    //   fontSize: 18,
    // },
  },
}));

const CreateAssetQuestionsTable = ({
  sectionQuestionsData,
  handlEditedQuestionData,
  editedQuestionData,
}) => {
  const [localBooleanState, setLocalBooleanState] = useState("");
  //state to show a policy list
  const [openPolicyCardList, setOpenPolicyCardList] = useState(null);
  const open = Boolean(openPolicyCardList);

  const showQueValue = (type, questionData) => {
    if (editedQuestionData.length > 0) {
      if (
        editedQuestionData.find(
          (quesData) =>
            quesData.card_question_id === questionData.card_question_id
        )?.ans[type] === undefined
      ) {
        if (questionData?.answer_field === null) {
          return null;
        } else {
          let stringAns = questionData?.answer_field.replace(/'/g, '"');
          let parseAns = JSON.parse(stringAns);
          return parseAns[type];
        }
      } else {
        return (
          editedQuestionData.length > 0 &&
          editedQuestionData.find(
            (quesData) =>
              quesData.card_question_id === questionData.card_question_id
          )?.ans[type]
        );
      }
    } else {
      if (questionData?.answer_field === null) {
        return null;
      } else {
        let stringAns = questionData?.answer_field.replace(/'/g, '"');
        let parseAns = JSON.parse(stringAns);
        return parseAns[type];
      }
    }
  };

  const renderDesiredResAnswer = (answer) => {
    let answerText = answer;
    if (answer === "I don't know") {
      answerText = "-";
    } else if (answer === true) {
      answerText = "Yes";
    } else if (answer === false) {
      answerText = "No";
    }
    return answerText;
  };
  return (
    <TableContainer
      component={Paper}
      sx={
        window.innerHeight <= 750 ? { maxHeight: "72%" } : { maxHeight: "80%" }
      }
      id={"CreateAssetQuestionFormTableCont"}
    >
      <Table
        style={{ fontSize: "1rem" }}
        stickyHeader
        aria-label="sticky table"
      >
        {" "}
        {/* Default font size, adjust as needed */}
        <TableHead>
          <TableRow style={{ background: "#DDE6EA" }}>
            <StyledTableCell style={{ width: "55%" }}>
              Questions
            </StyledTableCell>
            <Tooltip placement="top" title={"Update reponses here"}>
              <StyledTableCell style={{ width: "35%" }}>
                Your Responses
              </StyledTableCell>
            </Tooltip>
            <StyledTableCell style={{ width: "10%" }}>
              Desired Response
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sectionQuestionsData.questions?.map(
            (questionData) =>
              questionData.is_visible &&
              questionData?.answer_type?.includes("boolean") && (
                <TableRow
                  key={questionData.card_question_id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <StyledTableBodyCell>
                    {questionData.is_required && (
                      <span style={{ color: "#F25757" }}>* </span>
                    )}
                    {questionData.question}
                    {questionData?.policy_tag !== "NULL" &&
                      questionData?.policy_tag !== "" &&
                      questionData?.policy_tag?.split(",").length > 0 && (
                        <span style={{ display: "block", marginLeft: "25px" }}>
                          <img
                            src={PolicyIcon}
                            alt=""
                            style={{
                              marginRight: "5px",
                              width: "0.75rem",
                            }}
                          />
                          {PolicyNameConst.policies}:
                          {questionData?.policy_tag
                            ?.split(",")
                            .map((tags, index) => {
                              return (
                                <>
                                  {(() => {
                                    if (index < 1) {
                                      return <span> {`  ${tags}`} </span>;
                                    }
                                  })()}
                                  {(() => {
                                    if (
                                      index + 1 ===
                                        questionData?.policy_tag?.split(",")
                                          .length &&
                                      questionData?.policy_tag?.split(",")
                                        .length > 1
                                    ) {
                                      return (
                                        <span
                                          className="policy_Tagging_Hidden_Value"
                                          onClick={(e) => {
                                            setOpenPolicyCardList(
                                              e.currentTarget
                                            );
                                          }}
                                        >
                                          +
                                          {questionData?.policy_tag?.split(",")
                                            .length - 1}
                                        </span>
                                      );
                                    }
                                  })()}
                                </>
                              );
                            })}
                        </span>
                      )}
                    <Popover
                      sx={{
                        "& .MuiPopover-paper": {
                          boxShadow: "none",
                          border: "0.5px solid #6D8291 ",
                          width: "325px",
                          minHeight: "0px",
                          color: "#666666",
                        },
                      }}
                      id={questionData.card_question_id}
                      open={open}
                      anchorEl={openPolicyCardList}
                      onClose={() => setOpenPolicyCardList(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      {questionData?.policy_tag != "NULL" &&
                        questionData?.policy_tag?.split(",").length > 0 &&
                        questionData?.policy_tag
                          ?.split(",")
                          .map((tags, index) => {
                            return (
                              <Typography
                                sx={{
                                  margin: "5px 10px 5px 10px",
                                  fontFamily: "var(--fontFamilyText)",
                                  fontSize: "0.9rem",
                                }}
                                key={index}
                              >
                                {" "}
                                {tags}
                              </Typography>
                            );
                          })}
                    </Popover>
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <RadioGroup
                      id={`CreateAssetQuestionResponse${questionData.card_question_id}`}
                      row
                      aria-labelledby="create-asset-asset-question-buttons-group-label"
                      name="create-asset-asset-question-row-radio-buttons-group"
                      value={
                        // localBooleanState === ""
                        //   ? showQueValue("boolean", questionData)
                        //   : localBooleanState
                        showQueValue("boolean", questionData)
                      }
                      onChange={(e) => {
                        if (e.target.value !== undefined) {
                          setLocalBooleanState(e.target.value);
                          handlEditedQuestionData(
                            questionData.card_question_id,
                            "boolean",
                            e.target.value
                          );
                        }
                      }}
                      onClick={(e) => {
                        if (e.target.value !== undefined) {
                          setLocalBooleanState(e.target.value);
                          handlEditedQuestionData(
                            questionData.card_question_id,
                            "boolean",
                            e.target.value
                          );
                        }
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "grid",
                          gridTemplateColumns: "max-content max-content 1fr",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value="yes"
                            control={<Radio color="default" />}
                            label="Yes"
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value="no"
                            control={<Radio color="default" />}
                            label="No"
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value=""
                            control={<Radio color="default" />}
                            label="Not sure"
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </StyledTableBodyCell>
                  <StyledTableBodyCell
                  // sx={{
                  //   padding: "4px 0px 4px 10px",
                  //   fontFamily: "var(--fontFamilyText)",
                  // }}
                  >
                    {renderDesiredResAnswer(questionData.desired_response)}
                  </StyledTableBodyCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreateAssetQuestionsTable;
